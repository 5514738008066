import React, { useState, useRef } from "react";
import { styled, get, useTheme } from "styling";
import { useTransition } from "react-spring";
import { useToggle } from "hooks/useToggle";
import { useLocalStorage } from "hooks/useLocalStorage";
import { DayNightToggle } from "components/DayNightToggle";
import { TodoItem, Todo } from "components/TodoItem";

export const App = () => {
  const { selectedTheme, setTheme } = useTheme();
  const [isNight, toggleNight] = useToggle(selectedTheme === "dark");

  const onToggle = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    toggleNight();
  };

  const [newTodo, setNewTodo] = useState<Todo>({ id: Date.now(), content: "" });
  const [todos, setTodos] = useLocalStorage<Todo[]>("todos", []);
  const allTodos = [
    { ...newTodo, isNew: true },
    ...todos.map((todo) => ({ ...todo, isNew: false })),
  ];
  const deletedIndex = useRef(0);

  const transitions = useTransition(allTodos, (todo) => todo.id, {
    from: {
      transform: `translate(-100vw, 0px)`,
    },
    enter: (item) => ({
      transform: `translate(0vw, ${allTodos.indexOf(item) * 80}px)`,
    }),
    leave: {
      transform: `translateX(100vw, ${deletedIndex.current * 80}px)`,
    },
    update: (item) => ({
      transform: `translate(0vw, ${allTodos.indexOf(item) * 80}px)`,
    }),
  });

  return (
    <Wrapper>
      <Header>
        <h1>Your Goals</h1>
        <DayNightToggle isNight={isNight} onToggle={onToggle} height={24} />
      </Header>
      <ListWrapper>
        <List>
          {transitions.map(({ item, props, key }) => (
            <TodoItem
              key={key}
              style={{ ...props }}
              todo={item}
              onAction={(action) => {
                if (action.type === "update") {
                  setNewTodo(action.payload);
                }
                if (action.type === "create") {
                  setNewTodo({ id: Date.now(), content: "" });
                  setTodos((prevTodos) => [action.payload, ...prevTodos]);
                }
                if (action.type === "delete") {
                  setTodos((prevTodos) =>
                    prevTodos.filter((todo, i) => {
                      if (todo.id !== action.payload.id) {
                        return true;
                      }
                      deletedIndex.current = i + 1;
                      return false;
                    }),
                  );
                }
              }}
              isNew={item.isNew}
              disabled={item.isNew && item.content === ""}
            />
          ))}
        </List>
      </ListWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${get.color("secondaryBackground")};
  color: ${get.color("text")};
  height: 100vh;
  width: 100vw;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${get.size(8)};
  background-color: ${get.color("header")};
  border-radius: 0 0 ${get.radius(4)} ${get.radius(4)};
  box-shadow: ${get.shadow(2)};
  padding: 0 ${get.space(4)};
  z-index: 1;

  h1 {
    margin: 0;
    font-size: ${get.fontSize(3)};
    font-weight: ${get.fontWeight(1)};
    color: ${get.color("buttonText")};
    font-family: ${get.font("heading")};
  }
`;

const ListWrapper = styled.div`
  height: calc(100vh - ${get.size(8)});
  padding: ${get.space(4)};
  background-color: ${get.color("secondaryBackground")};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  > :last-child {
    margin-bottom: ${get.space(4)};
  }
`;
