import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styling";
import { GlobalStyle } from "./GlobalStyle";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <ThemeProvider>
    <GlobalStyle />
    <App />
  </ThemeProvider>,
  document.getElementById("root"),
);

serviceWorker.register();
