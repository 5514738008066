import { createGlobalStyle, get } from "styling";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Source+Sans+Pro&display=swap');

  * {
    box-sizing: border-box;
    transition: background-color ${get.transition(1)};
  }

  body {
    margin: 0;
    color: ${get.color("text")};
    font-family: ${get.font("primary")};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: ${get.font("code")};
  }
`;
