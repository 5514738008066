import { minWidthMedia } from "./helpers";

const lightColors = {
  header: "#007DE1",
  background: "#ffffff",
  secondaryBackground: "#EBF0F5",
  text: "#3D3C3C",
  secondaryText: "#696969",
  buttonText: "#ffffff",
  add: "#48d20f",
  delete: "#eb001b",
  disabled: "#969696",
};

const darkColors: typeof lightColors = {
  header: "#000F19",
  background: "#0F0F0F",
  secondaryBackground: "#191E23",
  text: "#ffffff",
  secondaryText: "#969696",
  buttonText: "#ffffff",
  add: "#48AA0F",
  delete: "#C3001B",
  disabled: "#696969",
};

export const colors = {
  light: lightColors,
  dark: darkColors,
};

export const fonts = {
  primary: "'Source Sans Pro', 'Helvetica Neue', sans-serif",
  heading: "'Josefin Sans', 'Helvetica Neue', sans-serif",
  code: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
};

export const borderWidths = ["0px", "1px", "2px", "4px"] as const;

export const fontSizes = [
  "10px", // 0
  "12px", // 1
  "16px", // 2
  "24px", // 3
  "32px", // 4
  "40px", // 5
  "48px", // 6
  "56px", // 7
  "64px", // 8
  "72px", // 9
] as const;

export const fontWeights = ["400", "700", "900"] as const;

export const radii = ["0px", "2px", "4px", "8px", "16px"] as const;

export const sizes = [
  "0px", // 0
  "2px", // 1
  "4px", // 2
  "8px", // 3
  "16px", // 4
  "24px", // 5
  "32px", // 6
  "48px", // 7
  "64px", // 8
  "96px", // 9
  "128px", // 10
  "192px", // 11
  "256px", // 12
  "384px", // 13
  "512px", // 14
  "768px", // 15
  "1024px", // 16
] as const;

export const spaces = [
  "0px", // 0
  "2px", // 1
  "4px", // 2
  "8px", // 3
  "16px", // 4
  "24px", // 5
  "32px", // 6
  "48px", // 7
  "64px", // 8
  "96px", // 9
  "128px", // 10
  "192px", // 11
  "256px", // 12
  "384px", // 13
  "512px", // 14
  "768px", // 15
  "1024px", // 16
] as const;

export const shadows = [
  "0px", // 0
  "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)", // 1
  "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)", // 2
  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)", // 3
  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)", // 4
  "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)", // 5
] as const;

export const transitions = ["0", "0.15s ease-in-out", "0.3s ease-in-out"] as const;

export const breakpoints = {
  desktop: minWidthMedia("768px"),
};
