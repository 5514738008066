import React from "react";
import { styled, get, ColorProp } from "styling";
import { animated, AnimatedValue } from "react-spring";

export type Todo = {
  id: number;
  content: string;
};

type Props = {
  style?: AnimatedValue<object>;
  todo: Todo;
  onAction: (action: { type: "create" | "update" | "delete"; payload: Todo }) => void;
  isNew?: boolean;
  disabled?: boolean;
};

export const TodoItem = (props: Props) => {
  return (
    <Card style={props.style}>
      {props.isNew ? (
        <Input
          placeholder="Your future accomplishment..."
          value={props.todo.content}
          onChange={(event) =>
            props.onAction({
              type: "update",
              payload: { ...props.todo, content: event.currentTarget.value },
            })
          }
          onKeyPress={(event) => {
            if (event.key === "Enter" && !props.disabled) {
              props.onAction({ type: "create", payload: props.todo });
            }
          }}
        />
      ) : (
        <span>{props.todo.content}</span>
      )}
      <Button
        color={props.isNew ? "add" : "delete"}
        disabled={props.disabled}
        onClick={() =>
          props.isNew
            ? props.onAction({ type: "create", payload: props.todo })
            : props.onAction({ type: "delete", payload: props.todo })
        }
      >
        <Cross rotate={props.isNew ? 0 : 45}>
          <Line />
          <Line rotate={90} />
        </Cross>
      </Button>
    </Card>
  );
};

const Card = styled(animated.div)`
  position: absolute;
  background-color: ${get.color("background")};
  box-shadow: ${get.shadow(2)};
  border-radius: ${get.radius(3)};
  padding: ${get.space(4)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: ${get.size(14)};
`;

const Input = styled.input`
  background-color: ${get.color("background")};
  font-family: ${get.font("primary")};
  font-size: ${get.fontSize(2)};
  color: ${get.color("text")};
  border: none;
  padding: 0;
  flex: 1;

  ::placeholder {
    color: ${get.color("secondaryText")};
  }
`;

const Button = styled.button<{ color: ColorProp }>`
  border: none;
  padding: 0;
  height: ${get.size(6)};
  width: ${get.size(6)};
  border-radius: 50%;
  background-color: ${({ color, theme }) => theme.colors[color]};
  color: ${get.color("buttonText")};
  transition: ${get.transition(2)};

  :disabled {
    background-color: ${get.color("disabled")};
  }
`;

const Cross = styled.div<{ rotate?: number }>`
  position: relative;
  height: 100%;
  width: 100%;
  transform: rotate(${({ rotate }) => rotate}deg);
  transition: ${get.transition(2)};
`;

const Line = styled.div<{ rotate?: number }>`
  position: absolute;
  top: calc(50% - ${get.size(4)} / 2);
  left: calc(50% - ${get.size(1)} / 2);
  height: ${get.size(4)};
  width: ${get.size(1)};
  border-radius: ${get.radius(1)};
  background-color: ${get.color("buttonText")};
  transform: rotate(${({ rotate }) => rotate}deg);
`;
