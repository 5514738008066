import React, { Dispatch, SetStateAction } from "react";

export function useLocalStorage<T extends unknown>(
  key: string,
  initialValue: T,
  reviver?: Parameters<typeof JSON.parse>[1],
  replacer?: Parameters<typeof JSON.stringify>[1],
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = React.useState<T>(() => {
    const strValue = localStorage.getItem(key);
    if (strValue === null) {
      return null || initialValue;
    }
    return JSON.parse(strValue, reviver) || initialValue;
  });

  React.useEffect(() => {
    const strValue = localStorage.getItem(key);
    if (strValue === null) {
      setValue(null || initialValue);
      return;
    }
    setValue(JSON.parse(strValue, reviver) || initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, reviver]);

  React.useEffect(() => {
    const strValue = JSON.stringify(value, replacer);
    localStorage.setItem(key, strValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, replacer]);

  return [value, setValue];
}
