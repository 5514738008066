// Inspired by https://codepen.io/jsndks/pen/qEXzOQ?editors=1100

import React from "react";
import { styled, css } from "styling";

const dayInner = "#C0E6F6";
const dayOuter = "#81C0D5";
const sunInner = "#F5EB42";
const sunOuter = "#E4C74D";
const cloudInner = "#FFFFFF";
const cloudOuter = "#D4D4D2";
const nightInner = "#484848";
const nightOuter = "#202020";
const moonInner = "#FFFDF2";
const moonOuter = "#DEE1C5";
const craterInner = "#EFEEDA";
const stars = "#FCFCFC";

type Props = {
  isNight: boolean;
  onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  height?: number; // in px
};

export const DayNightToggle = ({ isNight, onToggle, height = 48 }: Props) => {
  return (
    <Toggle toggleHeight={height}>
      <ToggleInput
        type="checkbox"
        checked={isNight}
        onChange={onToggle}
        toggleHeight={height}
      />
      <Background toggleHeight={height} />
      <Switch toggleHeight={height}>
        <SwitchFigure toggleHeight={height} />
        <SwitchFigureAlt toggleHeight={height} />
      </Switch>
    </Toggle>
  );
};

const crater = (toggleHeight: number, top: number, right: number, size: number) => css`
  content: "";
  position: absolute;
  top: ${top}px;
  right: ${right}px;
  width: ${size}px;
  height: ${size}px;
  background-color: ${craterInner};
  border-radius: 100%;
  border: ${toggleHeight / 24}px solid ${moonOuter};
`;

const cloudBubble = (
  toggleHeight: number,
  top: number,
  right: number,
  width: number,
  height: number,
  deg: number,
) => css`
  content: "";
  display: block;
  position: relative;
  top: ${top}px;
  right: ${right}px;
  width: ${width}px;
  height: ${height}px;
  border: ${toggleHeight / 8}px solid ${cloudOuter};
  border-radius: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transform: rotateZ(${deg}deg);
  background-color: ${cloudInner};
`;

const Toggle = styled.div<{ toggleHeight: number }>`
  box-sizing: border-box;
  position: relative;
  display: inline-block;

  ${({ toggleHeight }) => `
    width: ${toggleHeight * 2}px;
    height: ${toggleHeight}px;
    border-radius: ${toggleHeight / 1.2}px;
  `};

  :before,
  :after {
    content: "";
    display: table;
  }
  :after {
    clear: both;
  }
  * {
    box-sizing: border-box;
  }
`;

const Background = styled.div<{ toggleHeight: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${dayInner};
  transition: all 0.12s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  ${({ toggleHeight }) => `
    border-radius: ${toggleHeight / 1.2}px;
    border: ${toggleHeight / 16}px solid ${dayOuter};
  `}
`;

const Switch = styled.div<{ toggleHeight: number }>`
  position: relative;
  border-radius: 50%;
  background-color: ${sunInner};
  transition: all 0.12s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  ${({ toggleHeight }) => `
    top: ${toggleHeight / 8}px;
    left: ${toggleHeight / 8}px;
    width: ${toggleHeight * 0.75}px;
    height: ${toggleHeight * 0.75}px;
    border: ${toggleHeight / 16}px solid ${sunOuter};
  `}
`;

const SwitchFigure = styled.div<{ toggleHeight: number }>`
  position: absolute;
  display: block;
  background-color: ${cloudInner};
  transform: scale(0.4);
  transition: all 0.16s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  ${({ toggleHeight }) => `
    bottom: -${toggleHeight / 5}px;
    left: -${toggleHeight / 8}px;
    width: ${toggleHeight / 0.6}px;
    height: ${toggleHeight / 1.5}px;
    border: ${toggleHeight / 8}px solid ${cloudOuter};
    border-radius: ${toggleHeight / 2.4}px;
  `}

  :after {
    ${({ toggleHeight }) =>
      cloudBubble(
        toggleHeight,
        -toggleHeight / 0.8,
        -toggleHeight / 1.15,
        toggleHeight / 3,
        toggleHeight / 3,
        80,
      )};
  }
  :before {
    ${({ toggleHeight }) =>
      cloudBubble(
        toggleHeight,
        -toggleHeight / 1.9,
        -toggleHeight / 4,
        toggleHeight / 1.5,
        toggleHeight / 1.5,
        30,
      )};
  }
`;

const SwitchFigureAlt = styled.div<{ toggleHeight: number }>`
  transition: all 0.16s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(0);
  ${({ toggleHeight }) =>
    crater(toggleHeight, toggleHeight / 12, toggleHeight / 24, toggleHeight / 8)};

  :before {
    ${({ toggleHeight }) =>
      crater(toggleHeight, -toggleHeight / 12, toggleHeight / 3.5, toggleHeight / 8)};
  }

  :after {
    ${({ toggleHeight }) =>
      crater(toggleHeight, toggleHeight / 3, toggleHeight / 5, toggleHeight / 24)};
  }

  ${({ toggleHeight }) => `
    box-shadow: -${toggleHeight / 1.5}px -${toggleHeight / 9}px 0 -${toggleHeight /
    36}px ${stars},
      -${toggleHeight / 0.77}px -${toggleHeight / 6.5}px 0 -${toggleHeight /
    48}px ${stars},
      -${toggleHeight / 1.06}px ${toggleHeight / 14}px 0 -${toggleHeight / 24}px ${stars},
      -${toggleHeight / 0.66}px ${toggleHeight / 8}px 0 -${toggleHeight / 48}px ${stars},
      -${toggleHeight / 0.87}px ${toggleHeight / 3.5}px 0 -${toggleHeight /
    24}px ${stars},
      -${toggleHeight / 1.2}px ${toggleHeight / 2}px 0 -${toggleHeight / 48}px ${stars},
      -${toggleHeight / 0.7}px ${toggleHeight / 2.5}px 0 -${toggleHeight / 36}px ${stars};
  `}
`;

const ToggleInput = styled.input<{ toggleHeight: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;

  ${({ toggleHeight }) => `
    border-radius: ${toggleHeight / 1.2}px;

    :checked {
      ~ ${Switch} {
        margin-left: ${toggleHeight}px;
        border-color: ${moonOuter};
        background-color: ${moonInner};
      }

      ~ ${Background} {
        background-color: ${nightInner};
        border-color: ${nightOuter};
      }

      ~ ${Switch} ${SwitchFigure} {
        opacity: 0;
        transform: scale(0.1);
      }

      ~ ${Switch} ${SwitchFigureAlt} {
        transform: scale(1);
      }
    }
  `}
`;
