import * as styledComponents from "styled-components"; // eslint-disable-line
import { TupleIndices } from "types";
import React, { useContext } from "react";
import { useLocalStorage } from "hooks/useLocalStorage";
import * as theme from "./theme";

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeContext,
  ThemeProvider: BaseThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export { css, createGlobalStyle, keyframes, styled };

export type SelectableTheme = keyof typeof theme.colors;
export type Colors = (typeof theme.colors)[SelectableTheme];

export type Theme = Omit<typeof theme, "colors"> & { colors: Colors };

export type ColorProp = keyof Colors;
export type FontProp = keyof typeof theme.fonts;
export type BorderWidthProp = TupleIndices<typeof theme.borderWidths>;
export type FontSizeProp = TupleIndices<typeof theme.fontSizes>;
export type FontWeightProp = TupleIndices<typeof theme.fontWeights>;
export type RadiusProp = TupleIndices<typeof theme.radii>;
export type SizeProp = TupleIndices<typeof theme.sizes>;
export type ShadowProp = TupleIndices<typeof theme.shadows>;
export type SpaceProp = TupleIndices<typeof theme.spaces>;
export type TransitionProp = TupleIndices<typeof theme.transitions>;

export type ThemedStyledProps = styledComponents.ThemedStyledProps<{}, Theme>;

/**
 * Usage:
 * ```
 * import { styled, get } from "styling";
 *
 * const Wrapper = styled.div`
 *   color: ${get.color("text")};
 *   height: ${get.size(10)};
 *   padding: ${get.space(3)};
 * `;
 * ```
 */
export const get = {
  /** Use with `border-width` */
  borderWidth: (borderWidth: BorderWidthProp) => (props: ThemedStyledProps) =>
    props.theme.borderWidths[borderWidth],

  /** Use with `color`, `background-color`, `border-color` */
  color: (colorName: ColorProp) => (props: ThemedStyledProps) =>
    props.theme.colors[colorName],

  /** Use with `font-family` */
  font: (fontName: FontProp) => (props: ThemedStyledProps) => props.theme.fonts[fontName],

  /** Use with `font-size` */
  fontSize: (fontSize: FontSizeProp) => (props: ThemedStyledProps) =>
    props.theme.fontSizes[fontSize],

  /** Use with `font-weight` */
  fontWeight: (fontWeight: FontWeightProp) => (props: ThemedStyledProps) =>
    props.theme.fontWeights[fontWeight],

  /** Use with `border-radius` */
  radius: (radius: RadiusProp) => (props: ThemedStyledProps) => props.theme.radii[radius],

  /** Use with `width`, `height`, `min-width`, `max-width`, `min-height`, `max-height`, `line-height` */
  size: (size: SizeProp) => (props: ThemedStyledProps) => props.theme.sizes[size],

  /** Use with `box-shadow`, `text-shadow` */
  shadow: (shadow: ShadowProp) => (props: ThemedStyledProps) =>
    props.theme.shadows[shadow],

  /** Use with `margin`, `margin-top`, `margin-right`, `margin-bottom`, `margin-left`, `padding`, `padding-top`,
   *  `padding-right`, `padding-bottom`, `padding-left`, `grid-gap`, `grid-column-gap`, `grid-row-gap`
   */
  space: (space: SpaceProp) => (props: ThemedStyledProps) => props.theme.spaces[space],

  /** Use with `transition` */
  transition: (transition: TransitionProp) => (props: ThemedStyledProps) =>
    props.theme.transitions[transition],
};

const SelectedThemeContext = React.createContext<{
  selectedTheme: SelectableTheme;
  setTheme: React.Dispatch<React.SetStateAction<SelectableTheme>>;
}>({
  selectedTheme: Object.keys(theme.colors)[0] as SelectableTheme,
  setTheme: (() => {}) as React.Dispatch<React.SetStateAction<SelectableTheme>>, // eslint-disable-line
});

export function ThemeProvider(props: React.PropsWithChildren<{}>) {
  const [selectedTheme, setTheme] = useLocalStorage("selectedTheme", Object.keys(
    theme.colors,
  )[0] as SelectableTheme);

  return (
    <SelectedThemeContext.Provider value={{ selectedTheme, setTheme }}>
      <SelectedThemeContext.Consumer>
        {({ selectedTheme }) => (
          <BaseThemeProvider
            theme={{
              ...theme,
              colors: theme.colors[selectedTheme],
            }}
          >
            <>{props.children}</>
          </BaseThemeProvider>
        )}
      </SelectedThemeContext.Consumer>
    </SelectedThemeContext.Provider>
  );
}

export function useTheme() {
  const theme = useContext(ThemeContext);
  const { selectedTheme, setTheme } = useContext(SelectedThemeContext);
  return { theme, selectedTheme, setTheme };
}
